<template>
	<el-container class="home-container">
		<!-- 头部区域 -->
		<el-header>
			<div>
				<img src="../assets/logo.png" alt="" />
				<span>后台管理系统</span>
			</div>
			<div class="btn">
				<div style="padding: 0 20px;">{{adminUsername}}</div>

				<el-button style="margin-right:12px;" type="info" @click="logout">退出</el-button>
			</div>
		</el-header>
		<!-- 页面主体区域 -->
		<el-container>
			<!-- 侧边栏 -->
			<el-aside :width="isCollapse ? '64px' : '200px'">
				<div class="toggle-button" @click="toggleCollapse">|||</div>
				<!-- 侧边栏菜单区域 -->
				<el-menu background-color="#333744" text-color="#fff" active-text-color="#409EFF" unique-opened
					:collapse="isCollapse" :collapse-transition="false" router :default-active="activePath">
					<el-submenu index="10">
						<!-- 首页 -->
						<template slot="title">
							<!-- 图标 -->
							<i class=" el-icon-s-home"></i>
							<!-- 文本 -->
							<span @click="getHome">首页</span>
						</template>
						<!-- 二级菜单 -->
					</el-submenu>
					<!-- 首页 -->
					<!-- 用户管理 -->
					<el-submenu index="1">
						<template slot="title">
							<!-- 图标 -->
							<i class="el-icon-user"></i>
							<!-- 文本 -->
							<span>用户管理</span>
						</template>
						<!-- 用户管理 -->
						<el-menu-item index="/users" @click="saveNavState('/users')" >
							<template slot="title">
								<!-- 图标 -->
								<i class="el-icon-menu"></i>
								<!-- 文本 -->
								<span>用户管理</span>
							</template>
						</el-menu-item>
							<!-- 管理员 -->
						<el-menu-item index="/admin" @click="saveNavState('/admin')" v-if="admin">
							<template slot="title">
								<!-- 图标 -->
								<i class="el-icon-menu"></i>
								<!-- 文本 -->
								<span>管理员</span>
							</template>
						</el-menu-item>
					</el-submenu>
					<!-- 用户管理 -->
					<!-- 商品管理 -->
					<el-submenu index="2">
						<!-- 商品管理 -->
						<template slot="title">
							<!-- 图标 -->
							<i class="el-icon-goods"></i>
							<!-- 文本 -->
							<span>商品管理</span>
						</template>
						<!-- 二级菜单 -->
						<el-menu-item index="/list" @click="saveNavState('/list')">
							<template slot="title">
								<!-- 图标 -->
								<i class="el-icon-menu"></i>
								<!-- 文本 -->
								<span>商品列表</span>
							</template>
						</el-menu-item>
					</el-submenu>
					<!-- 分类管理 -->
					<template slot="title">
						<!-- 图标 -->
						<i class="el-icon-document"></i>
						<!-- 文本 -->
						<span>分类管理</span>
					</template>
					<!-- 二级菜单 -->
					<el-menu-item index="/fenlei" @click="saveNavState('/fenlei')">
						<template slot="title">
							<!-- 图标 -->
							<i class="el-icon-menu"></i>
							<!-- 文本 -->
							<span>分类列表</span>
						</template>
					</el-menu-item>
					</el-submenu>
					<!-- 订单管理 -->
					<el-submenu index="3">
						<template slot="title">
							<!-- 图标 -->
							<i class="el-icon-s-claim"></i>
							<!-- 文本 -->
							<span>订单管理</span>
						</template>
						<!-- 二级菜单 -->
						<el-menu-item index="/order" @click="saveNavState('/order')">
							<template slot="title">
								<!-- 图标 -->
								<i class="el-icon-menu"></i>
								<!-- 文本 -->
								<span>订单列表</span>
							</template>
						</el-menu-item>
					</el-submenu>
					<!-- 评论管理 -->
					<el-submenu index="4">
						<template slot="title">
							<!-- 图标 -->
							<i class="el-icon-chat-dot-square"></i>
							<!-- 文本 -->
							<span>评论管理</span>
						</template>
						<!-- 二级菜单 -->
						<el-menu-item index="/pinglun" @click="saveNavState('/pinglun')">
							<template slot="title">
								<!-- 图标 -->
								<i class="el-icon-menu"></i>
								<!-- 文本 -->
								<span>评论列表</span>
							</template>
						</el-menu-item>
					</el-submenu>
					<!-- 公告管理 -->
					<el-submenu index="5">
						<template slot="title">
							<!-- 图标 -->
							<i class="el-icon-s-promotion"></i>
							<!-- 文本 -->
							<span>公告管理</span>
						</template>
						<!-- 二级菜单 -->
						<el-menu-item index="/gonggao" @click="saveNavState('/gonggao')">
							<template slot="title">
								<!-- 图标 -->
								<i class="el-icon-menu"></i>
								<!-- 文本 -->
								<span>列表</span>
							</template>
						</el-menu-item>
					</el-submenu>
			<!-- 公告管理 -->
					<el-submenu index="10">
						<template slot="title">
							<!-- 图标 -->
							<i class="el-icon-s-promotion"></i>
							<!-- 文本 -->
							<span>视频管理</span>
						</template>
						<!-- 二级菜单 -->
						<el-menu-item index="/video" @click="saveNavState('/video')">
							<template slot="title">
								<!-- 图标 -->
								<i class="el-icon-menu"></i>
								<!-- 文本 -->
								<span>列表</span>
							</template>
						</el-menu-item>
					</el-submenu>
				</el-menu>
			</el-aside>
			<!-- 右侧内容主体 -->
			<el-main>
				<!-- 路由占位符 -->
				<router-view></router-view>
			</el-main>
		</el-container>
	</el-container>
</template>

<script>
	export default {
		data() {
			return {
				// 左侧菜单数据
				menulist: [],
				adminUsername: localStorage.getItem('username'),
				// 是否折叠
				isCollapse: false,
				// 被激活的链接地址
				activePath: '',
				admin:false

			
			}
		},
		created() {
			// this.activePath = window.sessionStorage.getItem('activePath')
			  this.getAdmin()
		},
		methods: {
			getHome() {
				this.$router.push('/welcome');

			},
			// 管理员权限
			getAdmin(){
			 const username=localStorage.getItem('username')

					
				if(username != 'admin'){
				 this.admin=false
				}else{
			     this.admin=true
					
				}
				
			},
			
			
			// 退出登录功能
			logout() {
				// 弹出确认框提示用户是否确认退出
				const confirmLogout = confirm('确认退出登录？');
				if (confirmLogout) {
					// 清除localStorage中的登录信息
					localStorage.removeItem('username');
					localStorage.removeItem('isLoggedIn');
					// 提示退出成功
					this.$message.success('退出登录成功');
					// 重定向至登录页面
					this.$router.push('/login');
				}
			},

			// 点击按钮，切换菜单的折叠与展开
			toggleCollapse() {
				this.isCollapse = !this.isCollapse
			},
			// 保存链接的激活状态
			saveNavState(activePath) {
				window.sessionStorage.setItem('activePath', activePath)
				this.activePath = activePath
			},
		},
	}
</script>

<style lang="less" scoped>
	.home-container {
		height: 100%;
	}

	.el-header {
		background-color: #373d41;
		display: flex;
		justify-content: space-between;
		padding-left: 0;
		align-items: center;
		color: #fff;
		font-size: 20px;
		// padding: 15px 0;
		height: 80px !important;

		>div {
			display: flex;
			align-items: center;

			span {
				margin-left: 15px;
			}
		}

		img {
			width: 60px;
			height: 60px;
			border-radius: 100%;
			margin: 15px 0px 15px 15px;
		}
	}

	.el-aside {
		background-color: #333744;

		.el-menu {
			border-right: none;
		}
	}

	.el-main {
		background-color: #eaedf1;
	}


	.toggle-button {
		background-color: #4a5064;
		font-size: 10px;
		line-height: 24px;
		color: #fff;
		text-align: center;
		letter-spacing: 0.2em;
		cursor: pointer;
	}
</style>